<template>
  <div>
    <empty-list
      text="Não há novas notificações"
      v-if="!notifications"
      size="80"
    />

    <cards-list grid="1" gap="12" v-else>
      <div class="w-100 position-relative mt-2 p-0 col-md-6 mx-auto">
        <h5 class="my-4">Notificações ({{ notifications.length }})</h5>
        <b-card
          role="button"
          v-for="(notification, index) in notifications"
          :key="index"
          :border-variant="notification.was_answered ? '' : 'secondary'"
          class="d-flex item-notification mb-3 card-hoverable"
          body-class="d-flex"
          @click="handleGoToNotification(notification)"
        >
          <avatar
            height="50px"
            width="50px"
            :image="require('@/assets/logo.svg')"
            no-shadow
          />
          <div class="px-3">
            <strong :class="notification.was_answered ? '' : 'text-secondary'">
              {{ notification.title }}
            </strong>
            <div class="mr-3 mt-2" style="max-width: 90%;">
              <div v-html="getDescription(notification)" />
            </div>
          </div>
          <div
            class="position-absolute t-0 icon-close d-flex flex-column align-items-end"
          >
            <small class="d-block mt-2">
              {{ moment(notification.created_at).format("DD/MM/YYYY HH:mm") }}
            </small>
            <b-badge
              v-if="!notification.was_answered"
              variant="secondary"
              class="mt-2 text-center d-table"
            >
              Nova
            </b-badge>
          </div>
        </b-card>
      </div>
    </cards-list>
    <b-modal
      modal-class="overflow-hidden"
      centered
      static
      :scrollable="false"
      hide-header
      size="lg"
      id="notification-drawer"
    >
      <Nda :legal_responsible="legal_responsible" preview />
      <template #modal-footer="{ close }">
        <b-button variant="primary" size="sm" @click="close()">Fechar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Nda from "@/pages/InboundProcess/nda";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      legal_responsible: {},
    };
  },
  components: {
    Nda,
  },

  computed: {
    ...mapGetters(["user", "notifications"]),
  },
  methods: {
    ...mapActions([
      "hide_bottom_menu",
      "hide_application_header",
      "set_readed_notification",
    ]),

    getDescription(notification) {
      const { type, context } = notification;
      if (type === "s") {
        const { service } = context;
        const { borrower } = service;
        const money = this.$options.filters.money(service.contract_value_total);

        return `A empresa <b>${borrower.social_reason}</b> te
        convidou para participar da licitação do serviço <b>${service.scope}</b> 
        com um contrato de <b>${money}</b>.
        `;
      }
      return "Uma cópia do documento de NDA foi enviado para seu e-mail cadastrado na plataforma";
    },

    markAsReaded(id) {
      this.set_readed_notification(id);
    },

    handleGoToNotification(notification) {
      this.markAsReaded(notification.id);
      if (notification.type === "n") {
        this.legal_responsible = { id: this.user.id };
        this.$bvModal.show("notification-drawer");
        return;
      }

      this.$router.push(`/invite-homologation/detail?key=${notification.key}`);
    },
  },

  mounted() {
    this.hide_application_header(false);
  },
  beforeDestroy() {
    this.hide_application_header(true);
  },
};
</script>

<style lang="scss">
.icon-close {
  top: 15px;
  right: 20px;
  font-size: 14px;
}
.item-notification {
  min-height: 100px;
  position: relative;
  width: calc(100% - 1px);
}
</style>
